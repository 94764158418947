@import '../../_variables';

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

.task-view {
  padding: 0 0 0px 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .filter-container {
    width: 100%;
  }

  .task-table-container {
    width: 100%;
    overflow: auto;
    max-height: 600px;

    .head-cell {
      text-align: center;

      &.left {
        text-align: left;
      }
    }

    td {
      border-bottom: 1px solid #999;
    }
  }

  .filter-row {

    // padding-right: 48px;
    .btn-search {
      background-color: #FFF !important;
      border-radius: 0 !important;
      margin-left: 8px;
      margin-right: 36px;
      padding: 8px;
    }

    .search-row {
      .filter-select {
        flex: 1;
        margin: 0 4px;

        .input-group {
          margin-bottom: 0;
        }
      }

      .item {
        width: 20%;
      }

      .icon-search {
        font-size: 16px;
        top: calc(50% - 6px);
      }

      .input-group input {
        border-color: #707070;
        color: #254343;
        width: 100%;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: #254343;
          opacity: 1;
        }
      }
    }

    .search-input {
      background-color: #FFF;
      min-height: 47px;

      .input-group {
        margin: 0;

        .input-container {
          border: none;
        }

        input::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: #999999;
          opacity: 1;
        }
      }
    }

  }

  .task-table {
    width: 100%;
    margin-top: 32px;
    padding-bottom: 12px;
    background-color: #FFF;

    .sort-icon {
      margin-left: 4px;
      width: 7px;
      height: 9px;
    }
    td.task-cell.justify{
      text-align: justify;
    }
    th.head-cell.justify{
      text-align: left;
    }
    .task-row {
      &:hover {
        cursor: pointer;
        background-color: #eee;
      }

      .task-cell {
        text-align: center;

        &.left {
          text-align: left;
        }

        .pin {
          font-size: 20px;
          color: #D8D8D8;

          &.pinned {
            color: #02797E;
          }
        }

        .status {

          &.open,
          &.pending {
            color: #F7F7F7;
            background-color: #FC4343;
          }

          &.responded,
          &.revision {
            color: #F7F7F7;
            background-color: #FCBC03;
          }

          &.complete,
          &.compliant {
            color: #030303;
            background-color: #87FC87;
          }

          &.skipped,
          &.abandoned,
          &.duplicate,
          &.redundant {
            color: #F7F7F7;
            background-color: #787878;
          }

          &.reviewing {
            color: #030303;
            background-color: #FCFC87;
          }

          &.reviewed {
            color: #030303;
            background-color: #43DD43;
          }

          &.closed {
            color: #F7F7F7;
            background-color: #238723;
          }

          &.inprogress {
            color: #030303;
            background-color: #FCFC43;
          }

          &.hold {
            color: #F7F7F7;
            background-color: #CC0303;
          }
        }

        .pin-img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }

    .header-row {
      th {
        padding: 14px 8px;
      }
    }

    .data-row {
      color: #254343;

      &.pinned {
        background-color: rgba($color: #073C3C, $alpha: 0.1);
      }

      td {
        vertical-align: top;
        padding: 16px 8px;
      }
    }
  }

  .btn-bulk {
    margin-right: 16px;
  }
}

.task-dialog-confirmation {
  .title {
    color: #FF3333;
  }

  .subtitle {
    color: #072C3C;
  }

  .close {
    margin-top: 8px;
    .btn-text {
      color: #FF3333;
    }
  }
}

.new-task-dialog {

  .btn-create-form,
  .btn-cancel-form {
    margin-right: 25px;
    padding: 8px 15px !important;
  }

  .btn-row {
    width: 100%;
    padding-left: 80px;
  }

  .MuiPaper-root {
    width: 100%;
  }

  .error {
    box-shadow: 0px 0px 4px rgb(244, 67, 54);
    // border-color: rgb(244, 67, 54);
    border: none;
  }

  .hint-optinal {
    color: #707070;
  }

  .input-repeat {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #254343;

    label {
      margin: 0 0 2px 0;
    }

    .repeat-date {
      align-items: center;
      margin: 0;
      // width: calc(100% / 3);
      width: auto;
      .MuiInputAdornment-root{
        margin-left: -16px;
      }
      .input-container {
        border: none;
      }
      &:nth-child(1){
        margin-right: 24px;
      }
    }


    .input-occur {
      align-items: center;
      margin-bottom: 0;
      height: 40px;
      margin-right: 24px;
      label {
        margin-right: 6px;
      }
    }
  }
  .date{
    button:disabled, input:disabled{
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}

.view-task-dialog {
  
  .container {
    background-color: #FFF;
    padding: 30px;
    height: 100%;
    position: relative;

    .info-row {
      margin-top: 8px;
    }

    .activity-row {
      .MuiGrid-item {
        padding-bottom: 16px;
      }

      .title {
        padding-bottom: 8px;
      }
    }

    .pin {
      color: #FFFFFF;
      font-size: 16px;
    }

    .left {

      .name {
        color: #073C3C;
        margin: 8px 0 16px 0;
      }

      .label {
        color: #254343;
        margin-bottom: 8px;
        font-weight: $font-weight-bold;
      }

      .desc {
        margin: 0;
        color: #254343;
        white-space: pre-wrap;
        text-align: justify;
      }

      .info {
        margin-bottom: 12px;
      }

      .chat-section {
        border: 1px solid #707070;
        border-radius: 2px;
        padding: 8px;
        color: #254343;
        overflow: hidden;
        height: 150px;

        .heading {
          font-weight: $font-weight-bold;
        }

        .row {
          margin-top: 8px;
        }

        .name {
          margin-right: 4px;
        }

        .msg {
          margin: 4px 8px;
        }

        .evidence {
          .file {
            color: #1177CC;
            word-break: break-all;
            cursor: pointer;
          }

          .name {
            color: #254343;
          }
        }

        .creator {
          font-weight: $font-weight-medium;
        }

        .date {
          word-break: break-all;
          margin-left: 6px;
        }

        .content {
          height: 94%;
          overflow: hidden auto;
        }
      }

      .comment-input-sec {
        border: 0.2px solid #707070;
        border-radius: 2px;
        padding: 8px;

        textarea {
          width: 100%;
          outline: none;
          border: none;
          resize: none;
        }

        .app-btn {
          padding: 0 8px !important;
        }
      }

      .send-evidence {
        border: 0.2px solid #707070;
        border-radius: 2px;
        padding: 8px;
        color: #9B9D9D;

        .attach {
          padding: 0 8px !important;
        }
      }
    }

    .right {
      .btn {
        padding: 2px 8px !important;
        margin-left: 16px;
      }

      .btn-pin {
        padding: 10px 16px !important;
      }

      .status-row {
        margin: 40px 0;
        align-items: flex-end;
      }

      .pin-task {
        width: 20px;
        height: 20px;
      }

      .status-select {
        margin-bottom: 0;

        .input-container {
          border: none;
          background-color: #D8D8D8;
          height: 40px;
          color: #254343;
        }
      }

      .detals-row {
        margin-bottom: 16px;
        color: #254343;

        .val{
          flex: 2;
          margin: 0 0 0 8px;
          .avatar-select{
            margin-right: 24px;
          }
        }
        ul {
          margin: 0;
          padding: 0;
        }

        .MuiSelectUnstyled-popper {
          width: calc(50% - 16px);
        }

        .link {
          color: #1177CC;
        }

        .date {
          margin-bottom: 0;
        }

        .input-container {
          border: none;
          padding: 0;
          min-height: unset;
        }
      }

      .accord-head {
        border-bottom: 1px solid #707070 !important;
      }

      .risk {
        color: #254343;

        .risk-num {
          font-size: 3.6rem;
          color: #FF3333;
        }

        .risk-label {
          color: #072C3C;
          margin-right: 16px;
          font-weight: $font-weight-medium;
        }

        .result-readonly {
          margin-bottom: 0;

          .input-container {
            border: none;
          }
        }

        .result {
          margin-top: 16px;

          .input-container {
            background-color: transparent;
          }
        }

        .observ {
          margin-top: 16px;
          .value {
            margin: 8px 0 0 16px;
          }
          .div{
            margin-bottom: 8px;
          }
          .entry{
            margin-top: 8px;
            padding-left: 12px;
          }
        }

        .save {
          padding: 4px 8px !important;

          &:disabled {
            .btn-text {
              color: rgba(0, 0, 0, 0.26)
            }
          }
        }
      }
    }
  }

  .view-task-paper {
    width: 95%;
    max-width: 95%;

    .fetching {
      height: 450px;
    }
  }

  .task-accordion {
    border: 1px solid #707070;
    box-shadow: none;

    &.risk {
      background-color: #EDFFFF;
      .activity {
        margin-bottom: 16px;
        .content{
          padding-left: 8px;
          .input-group{
            margin-bottom: 0;
          }
        }
        .observe-list{
          height: 136px;
          overflow: hidden auto;
          .date{
            text-align: right;
          }
        }
      }
    }
  }
  .btn-close{
    position: absolute;
    top: 16px;
    right: 16px;
    box-shadow: none;
    .btn-text{
      color: #FF3333;
    }
    &:hover{
      box-shadow: none;
    }
  }
}

.task-pg {
  color: #073C3C;

  .Mui-selected {
    background-color: #1155CC !important;
    color: #FFF;
  }

  svg {
    font-size: inherit;
  }
}

.dialog-title {
  padding: 21px 25px !important;
}

.dialog-content {
  padding-left: 90px !important;
}

.bulk-upload {
  .back {
    margin-bottom: 16px;
  }

  .btn-back {
    color: #073C3C;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
  }

  .template {
    font-weight: $font-weight-medium;
    color: $color-text-blue;
  }

  .file-control {
    border: 1px solid #254343;
    border-radius: 0;
    padding: 4px 7px 4px 15px;
    width: 100%;
    margin-top: 6px;

    .file-name {
      color: rgba($color: #868686, $alpha: 0.8);
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }

  .form-control {
    width: 35%;
    align-items: flex-end;
    margin-right: 45px;
  }

  .form-container {
    padding: 36px 46px;
  }

  .btn-upload,
  .btn-choose {
    .btn-text {
      font: inherit
    }
  }

  .btn-choose {
    width: 162px;
  }

  .form-row {
    width: 100%;
    align-items: flex-end;
  }

  .icon-file {
    width: 18px;
    height: 24px;
  }

  .msg-cont {
    margin: 12px 0;
    align-items: flex-start;

    .type {
      margin-right: 6px;

      &.success {
        color: #238787;
      }

      &.important {
        color: #FF3333;
      }
    }

    .msg {
      color: #254343;
    }
  }

  .prev-cont {
    h6 {
      font-weight: $font-weight-medium;
      margin: 24px 0;
      color: $color-text-green;
    }

    position: relative;

    .prev-table-cont {
      position: relative;
      background-color: #FFF;
      padding: 0 25px;

      th,
      td {
        color: #254343;
        font: inherit;
        border: none;
      }

      th {
        font-weight: $font-weight-medium;
      }

      td {
        font-weight: $font-weight-regular;
      }
    }
  }
}

.pin-dialog {
  padding: 16px;

  .heading {
    color: #254343;
    font-weight: $font-weight-medium;
    margin-bottom: 12px;
  }

  .subheading {
    color: #072C3C;
  }

  .action {
    margin-top: 63px;
  }
}