@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;1,400;1,500;1,600;1,900&family=Roboto:wght@300;400;500;900&display=swap');

@mixin get-font($index, $fam, $weight) {
  font-size: nth($font-list, $index);
  font-family: $fam  !important;
  font-weight: $weight  !important;
}

* {
  font-family: $font-roboto, sans-serif;
  font-weight: $font-weight-regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
.item {
  font-family: $font-exo2, sans-serif;
  font-weight: 300;
}

.h1 {
  font-size: 3.2rem !important;
}

.h2 {
  font-size: 2.8rem !important;
}

.h21 {
  font-size: 2.6rem !important;
}

.h3 {
  font-size: 2.4rem !important;
}

.h41 {
  font-size: 2.2rem !important;
}

.h4 {
  font-size: 2.1rem !important;
}

.h42 {
  font-size: 2rem !important;
}

.h5 {
  font-size: 1.8rem !important;
}

.h6 {
  font-size: 1.6rem !important;
}

.h7 {
  font-size: 1.4rem !important;
}

.h8 {
  font-size: 1.2rem !important;
}

.h9 {
  font-size: 1rem !important;
}

.h10 {
  font-size: 0.8rem !important;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

.fam-robo-med {
  font-weight: $font-weight-regular  !important;
}

// Navbar
.navbar {
  .item {
    @include get-font(7, $font-exo2, $font-weight-regular);
    color: $color-text-green;
  }

  .item.active {
    color: $color-text-blue;
  }
}

.menu-profile {
  .menu-name {
    @include get-font(5, $font-exo2, $font-weight-regular);
    color: $color-text-green;
  }

  .menu-email {
    @include get-font(2, $font-roboto, $font-weight-regular);
    color: $color-text-blue;
  }
}

.menu-options {
  @include get-font(3, $font-roboto, $font-weight-regular);
  color: $color-text-black;
}

.menu-signout {
  color: $color-text-red;
}

// Dashboard
.tbl-title {
  @include get-font(4, $font-exo2, $font-weight-medium);
}

.tbl-header th span {
  @include get-font(3, $font-exo2, $font-weight-regular);
}

.tbl-body {
  @include get-font(3, $font-roboto, $font-weight-regular);
}

.tbl-body,
.tbl-header {
  td:nth-child(1) {
    text-align: left;
  }

  th:nth-child(1) {
    text-align: left;
  }
}

// Task list
.filter-container {
  .title {
    @include get-font(3, $font-exo2, $font-weight-regular);
    color: $color-text-green;
    margin-right: 12px
  }

  .filter-select input {
    @include get-font(3, $font-roboto, $font-weight-regular);
  }

  .search-row * {
    font-size: 15px;
  }

  .add-btn {
    padding: 4px 16px;

    .btn-text {
      @include get-font(4, $font-roboto, $font-weight-medium);
    }
  }
}

.task-view {
  .task-table {
    .header-row {
      th span {
        @include get-font(2, $font-exo2, $font-weight-bold);
      }
    }

    .task-cell {
      @include get-font(3, $font-roboto, $font-weight-regular);
      color: $color-text-green2;

      .priority span,
      .status span {
        @include get-font(2, $font-roboto, $font-weight-regular);
      }
    }
  }
}

// Create Task UI
.dialog-title {
  @include get-font(7, $font-roboto, $font-weight-regular);
}

.dialog-content {
  label {
    // @include get-font(5, $font-roboto, $font-weight-regular);
  }

  input {
    @include get-font(4, $font-roboto, $font-weight-regular);
  }

  .max-len {
    @include get-font(3, $font-roboto, $font-weight-regular);
  }

  .MuiAutocomplete-groupLabel,
  .MuiAutocomplete-option {
    font-family: $font-roboto sans-serif;
    font-weight: $font-weight-regular;
    color: #254343
  }
}

.btn-row {
  .btn-text {
    @include get-font(4, $font-roboto, $font-weight-medium);
  }
}