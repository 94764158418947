$font-roboto: 'Roboto', sans-serif;
$font-exo2: 'Exo 2', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$color-text-green: #073C3C;
$color-text-green2: #254343;
$color-text-blue: #1177CC;
$color-text-red: #FF3333;
$color-text-white: #FFFFFF;
$color-text-black: #000000;
$font-list: 12px 14px 16px 18px 20px 21px 24px 28px;

