@import '../../_variables';

$color-table-header: #238787;
$color-table-row: #9BC9C9;
$color-table-alt-row: #EDFFFF;
.dash-view{
  padding: 30px;
  overflow: hidden auto;
  .pie{
    width: 100px !important;
    height: 275px !important;
  }
}
.tbl-cont {
  width: 100%;
  overflow: auto;
}
.tbl-cont table {
  width: 100%;
  border-collapse:collapse;
}
.tbl-cont th, .tbl-cont td {
  padding: 5px 10px;
}
.tbl-title {
  padding: 10px;
}
.tbl-header {
  background-color: $color-table-header;
  color: $color-text-white;
}
.tbl-body tr:nth-child(even) {
  background-color: $color-table-row;
}
.tbl-body tr:nth-child(odd) {
  background-color: $color-table-alt-row;
}
.tbl-cont td {
  padding: 5px 10px;
  text-align: center;
}
.menu-profile {
  list-style: none;
  padding: 0;

}
.chart-card{
  padding: 24px;
  .title{
    color: #073C3C;
    margin-bottom: 16px;
  }
}